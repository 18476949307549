import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9d899b8c = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _e4cf5342 = () => interopDefault(import('../pages/action/index.vue' /* webpackChunkName: "pages/action/index" */))
const _11b9128f = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _9885b230 = () => interopDefault(import('../pages/audit/index.vue' /* webpackChunkName: "pages/audit/index" */))
const _79e0bf0e = () => interopDefault(import('../pages/deviation/index.vue' /* webpackChunkName: "pages/deviation/index" */))
const _75eeee14 = () => interopDefault(import('../pages/it/index.vue' /* webpackChunkName: "pages/it/index" */))
const _d9b63e14 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _315e9ba0 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _46558ca6 = () => interopDefault(import('../pages/offline.vue' /* webpackChunkName: "pages/offline" */))
const _51cd85f8 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _4f38ca9c = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _678c370c = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _3fe9f046 = () => interopDefault(import('../pages/report/index.vue' /* webpackChunkName: "pages/report/index" */))
const _4617e338 = () => interopDefault(import('../pages/risk/index.vue' /* webpackChunkName: "pages/risk/index" */))
const _39f3d15e = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _0e6fc988 = () => interopDefault(import('../pages/styles.vue' /* webpackChunkName: "pages/styles" */))
const _7ec5c1ad = () => interopDefault(import('../pages/suggestion/index.vue' /* webpackChunkName: "pages/suggestion/index" */))
const _d4897b24 = () => interopDefault(import('../pages/task/index.vue' /* webpackChunkName: "pages/task/index" */))
const _f94b98a6 = () => interopDefault(import('../pages/unit/index.vue' /* webpackChunkName: "pages/unit/index" */))
const _1f1fa8e2 = () => interopDefault(import('../pages/verify/index.vue' /* webpackChunkName: "pages/verify/index" */))
const _487a47f0 = () => interopDefault(import('../pages/account/organizations.vue' /* webpackChunkName: "pages/account/organizations" */))
const _ae84743a = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _91c4aff0 = () => interopDefault(import('../pages/account/security.vue' /* webpackChunkName: "pages/account/security" */))
const _8abd2574 = () => interopDefault(import('../pages/audit/plan/index.vue' /* webpackChunkName: "pages/audit/plan/index" */))
const _58918345 = () => interopDefault(import('../pages/organization/billing.vue' /* webpackChunkName: "pages/organization/billing" */))
const _222e6e25 = () => interopDefault(import('../pages/organization/companies.vue' /* webpackChunkName: "pages/organization/companies" */))
const _aca08582 = () => interopDefault(import('../pages/organization/events.vue' /* webpackChunkName: "pages/organization/events" */))
const _89163f0c = () => interopDefault(import('../pages/organization/groups.vue' /* webpackChunkName: "pages/organization/groups" */))
const _6f1396f2 = () => interopDefault(import('../pages/organization/job.vue' /* webpackChunkName: "pages/organization/job" */))
const _b2c8aa3a = () => interopDefault(import('../pages/organization/members.vue' /* webpackChunkName: "pages/organization/members" */))
const _52d350c3 = () => interopDefault(import('../pages/organization/subscription.vue' /* webpackChunkName: "pages/organization/subscription" */))
const _31c2aaf4 = () => interopDefault(import('../pages/password/activate/index.vue' /* webpackChunkName: "pages/password/activate/index" */))
const _511b2ea4 = () => interopDefault(import('../pages/password/forgot/index.vue' /* webpackChunkName: "pages/password/forgot/index" */))
const _49aa1cc4 = () => interopDefault(import('../pages/password/reset/index.vue' /* webpackChunkName: "pages/password/reset/index" */))
const _2ed217de = () => interopDefault(import('../pages/task/group/index.vue' /* webpackChunkName: "pages/task/group/index" */))
const _399129ca = () => interopDefault(import('../pages/action/_id/index.vue' /* webpackChunkName: "pages/action/_id/index" */))
const _28588dd3 = () => interopDefault(import('../pages/audit/_id/index.vue' /* webpackChunkName: "pages/audit/_id/index" */))
const _f5f0ba0e = () => interopDefault(import('../pages/deviation/_id/index.vue' /* webpackChunkName: "pages/deviation/_id/index" */))
const _60badeff = () => interopDefault(import('../pages/it/_id/index.vue' /* webpackChunkName: "pages/it/_id/index" */))
const _6f9a2770 = () => interopDefault(import('../pages/report/_id/index.vue' /* webpackChunkName: "pages/report/_id/index" */))
const _5b5ca223 = () => interopDefault(import('../pages/risk/_id/index.vue' /* webpackChunkName: "pages/risk/_id/index" */))
const _974783d0 = () => interopDefault(import('../pages/suggestion/_id/index.vue' /* webpackChunkName: "pages/suggestion/_id/index" */))
const _d5309fd0 = () => interopDefault(import('../pages/unit/_id/index.vue' /* webpackChunkName: "pages/unit/_id/index" */))
const _7d176b7c = () => interopDefault(import('../pages/report/_id/_procedure/a/_appendix/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/a/_appendix/index" */))
const _0f8c5d4e = () => interopDefault(import('../pages/report/_id/_procedure/_content/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/_content/index" */))
const _4bd6499c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _9d899b8c,
    name: "account"
  }, {
    path: "/action",
    component: _e4cf5342,
    name: "action"
  }, {
    path: "/archive",
    component: _11b9128f,
    name: "archive"
  }, {
    path: "/audit",
    component: _9885b230,
    name: "audit"
  }, {
    path: "/deviation",
    component: _79e0bf0e,
    name: "deviation"
  }, {
    path: "/it",
    component: _75eeee14,
    name: "it"
  }, {
    path: "/login",
    component: _d9b63e14,
    name: "login"
  }, {
    path: "/logout",
    component: _315e9ba0,
    name: "logout"
  }, {
    path: "/offline",
    component: _46558ca6,
    name: "offline"
  }, {
    path: "/onboarding",
    component: _51cd85f8,
    name: "onboarding"
  }, {
    path: "/organization",
    component: _4f38ca9c,
    name: "organization"
  }, {
    path: "/register",
    component: _678c370c,
    name: "register"
  }, {
    path: "/report",
    component: _3fe9f046,
    name: "report"
  }, {
    path: "/risk",
    component: _4617e338,
    name: "risk"
  }, {
    path: "/search",
    component: _39f3d15e,
    name: "search"
  }, {
    path: "/styles",
    component: _0e6fc988,
    name: "styles"
  }, {
    path: "/suggestion",
    component: _7ec5c1ad,
    name: "suggestion"
  }, {
    path: "/task",
    component: _d4897b24,
    name: "task"
  }, {
    path: "/unit",
    component: _f94b98a6,
    name: "unit"
  }, {
    path: "/verify",
    component: _1f1fa8e2,
    name: "verify"
  }, {
    path: "/account/organizations",
    component: _487a47f0,
    name: "account-organizations"
  }, {
    path: "/account/password",
    component: _ae84743a,
    name: "account-password"
  }, {
    path: "/account/security",
    component: _91c4aff0,
    name: "account-security"
  }, {
    path: "/audit/plan",
    component: _8abd2574,
    name: "audit-plan"
  }, {
    path: "/organization/billing",
    component: _58918345,
    name: "organization-billing"
  }, {
    path: "/organization/companies",
    component: _222e6e25,
    name: "organization-companies"
  }, {
    path: "/organization/events",
    component: _aca08582,
    name: "organization-events"
  }, {
    path: "/organization/groups",
    component: _89163f0c,
    name: "organization-groups"
  }, {
    path: "/organization/job",
    component: _6f1396f2,
    name: "organization-job"
  }, {
    path: "/organization/members",
    component: _b2c8aa3a,
    name: "organization-members"
  }, {
    path: "/organization/subscription",
    component: _52d350c3,
    name: "organization-subscription"
  }, {
    path: "/password/activate",
    component: _31c2aaf4,
    name: "password-activate"
  }, {
    path: "/password/forgot",
    component: _511b2ea4,
    name: "password-forgot"
  }, {
    path: "/password/reset",
    component: _49aa1cc4,
    name: "password-reset"
  }, {
    path: "/task/group",
    component: _2ed217de,
    name: "task-group"
  }, {
    path: "/action/:id",
    component: _399129ca,
    name: "action-id"
  }, {
    path: "/audit/:id",
    component: _28588dd3,
    name: "audit-id"
  }, {
    path: "/deviation/:id",
    component: _f5f0ba0e,
    name: "deviation-id"
  }, {
    path: "/it/:id",
    component: _60badeff,
    name: "it-id"
  }, {
    path: "/report/:id",
    component: _6f9a2770,
    name: "report-id"
  }, {
    path: "/risk/:id",
    component: _5b5ca223,
    name: "risk-id"
  }, {
    path: "/suggestion/:id",
    component: _974783d0,
    name: "suggestion-id"
  }, {
    path: "/unit/:id",
    component: _d5309fd0,
    name: "unit-id"
  }, {
    path: "/report/:id/:procedure/a/:appendix",
    component: _7d176b7c,
    name: "report-id-procedure-a-appendix"
  }, {
    path: "/report/:id/:procedure/:content",
    component: _0f8c5d4e,
    name: "report-id-procedure-content"
  }, {
    path: "/",
    component: _4bd6499c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
